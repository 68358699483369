@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300..700&display=swap');

@import "./data/styles.css";

body {
	margin: 0;
	font-family: "Jura", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {


	font-family: "Jura", sans-serif;
}