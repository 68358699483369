.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
	justify-content: center;
	align-items: center;
}

.socials-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}